$(function () {
    initSearch();
    initNav();
    initSlick();
    initActueel();
    initUsps();
    initNieuws();
    initMagnific();
    initDetails();
    initContact();
    initAanbod();
    initServiceIcons();
    initEasyEquals();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        $nav.children('ul').attr('style', false);
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 768) {
            if ($('#search').length > 0) {
                $('#search').slideUp(function () {
                    $nav.slideToggle();
                });
            } else {
                $nav.slideToggle();

            }
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle')
                    $(ul).slideUp();
                }
            });

            if ($(this).next('ul').is(':hidden')) {
                e.preventDefault();
                $(this).next('ul').slideDown();
                $(this).children('span').addClass('toggle')
            }
        });
    }
}


var initMagnific = function(){
    $('.image-popup').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }
    });
}

function initSearch() {
    $btn_search = $('header .search-toggle');
    $search = $('#search');

    if ($search.length == 0) {
        $btn_search.hide();
    } else {

        $btn_search.click(function (e) {
            e.stopPropagation();
            e.preventDefault();

            if ($(window).width() < 768) {
                $('header nav').slideUp(function () {
                    $search.slideToggle();
                });
            }
        });

        $('select#plaats option').first().text('Kies een plaats');
//        $('select#prijs_min option').first().text('Minimum prijs');
//        $('select#prijs_max option').first().text('Maximum prijs');
//        $('select#prijs_min_huur option').first().text('Minimum prijs');
//        $('select#prijs_max_huur option').first().text('Maximum prijs');
        $('select#oppervlakte_min option').first().text('Minimum oppervlakte');
        $('select#oppervlakte_max option').first().text('Maximum oppervlakte');

//        $select_koophuur = $('#koophuur');
//        $select_koophuur.change(function () {
//            if ($select_koophuur.val() == 'koop') {
//                $('#search-huurprijs select').attr('disabled', 'disabled');
//                $('#search-koopprijs select').removeAttr('disabled');
//                $('#search-huurprijs').hide();
//                $('#search-koopprijs').show();
//            } else {
//                $('#search-koopprijs select').attr('disabled', 'disabled');
//                $('#search-huurprijs select').removeAttr('disabled');
//                $('#search-koopprijs').hide();
//                $('#search-huurprijs').show();
//            }
//        });
//        $select_koophuur.change();
    }
}

function initSlick() {
    if ($('#carousel').length > 0) {
        $('#carousel').slick({
            dots: true,
            arrows: false,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
}

function initActueel() {
    if ($('#actueel').length > 0) {
        $('#actueel').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
}

function initUsps() {
    if ($('#usps').length > 0) {
        $('#usps').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initNieuws() {
    if ($('#homepage #nieuws').length > 0) {
        $('#homepage #nieuws').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initDetails() {
    if ($('#details').length > 0) {
        initDetailSlider();
        initDetailButtons();
        initDetailOmschrijving();
        initStreetview();
        initMaps();
        initObjectPano();

        if ($('.lightbox').length > 0) {
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }
    }
}

function initDetailButtons() {
    if ($('.detail_buttons').length > 0) {
        var sections = {},
                offset = 175;

        $('.detail_buttons a').click(function () {
            $('.detail_buttons li').removeClass('active');
            $(this).parent().addClass('active');

            // adjust for fixed header
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    && location.hostname == this.hostname) {

                var target = $(this.hash);
                target = target.length ? target : $('[id=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - offset
                    }, 1000);
                    return false;
                }
            }
        });

        $('.detail_buttons a').each(function () {
            id = $(this).attr('href');

            if (id !== '#') {
                sections[id] = $(id).offset().top;
            }
        });

        $(document).scroll(function () {
            var pos = $(this).scrollTop();

            for (key in sections) {
                if (sections[key] > pos && sections[key] < pos + offset) {
                    $('.detail_buttons li').removeClass('active');
                    $('.detail_buttons a[href="' + key + '"]').parent().addClass('active');
                }
            }
        });
    }
}

function initDetailSlider() {
    if ($('#details-slider').length > 0) {
        $slider = $('#details-slider');
        $thumbs = $('#details-thumbs');

        $slider.slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '#details-thumbs'
        });

        $thumbs.slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            asNavFor: '#details-slider'
        });
    }
}


function initStreetview() {
    var geocoder = new google.maps.Geocoder();

    var options = {
        'address': $('#gmap_streetview').data('address')
    }
    if ($('#gmap_streetview').attr('data-lat') && $('#gmap_streetview').attr('data-lng')) {
        options = {
            'location': {
                lat: parseFloat($('#gmap_streetview').data('lat')),
                lng: parseFloat($('#gmap_streetview').data('lng'))
            }
        }
    }
    geocoder.geocode(options, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            var lookTo = results[0].geometry.location;
            var panoOptions = {
                position: lookTo,
                panControl: false,
                addressControl: false,
                linksControl: false,
                zoomControlOptions: false
            };
            var pano = new google.maps.StreetViewPanorama(document.getElementById('gmap_streetview'), panoOptions);
            var service = new google.maps.StreetViewService;
            service.getPanoramaByLocation(pano.getPosition(), 50, function (panoData) {
                if (panoData != null) {
                    var panoCenter = panoData.location.latLng;
                    var heading = google.maps.geometry.spherical.computeHeading(panoCenter, lookTo);
                    var pov = pano.getPov();
                    pov.heading = heading;
                    pano.setPov(pov);
                } else {
                    $('#gmap_streetview').html('Kan streetview niet genereren');
                }
            });
        } else {
            $('#gmap_streetview').html('Kan streetview niet genereren');
        }
    });
}

function initMaps() {
    if ($('#locatie').length > 0) {
        var latlng;
        var locationlatlng = {lat: parseFloat($('#gmap_locatie').data('lat')), lng: parseFloat($('#gmap_locatie').data('lng')) };
        var  searchOn = {'location': locationlatlng};

        if( $('#gmap_locatie').data('address') ) {
            var address = $('#gmap_locatie').data('address');
            searchOn = {'address': address};
        }

        var geocoder = new google.maps.Geocoder();

        geocoder.geocode( searchOn , function(results, status) {
            if (status == 'OK') {
                latlng = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());

                map = new google.maps.Map(document.getElementById("gmap_locatie"), {
                    zoom: 15,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    center: latlng,
                    scrollwheel: false
                });

                var marker = new google.maps.Marker({
                    position: latlng,
                    map: map,
                    icon: '/img/icon-map.png'
                });
            }
        });
    }
}

function initDetailOmschrijving() {
    if ($('a.meer').length > 0) {
        $('a.meer').click(function (e) {
            e.preventDefault();
            $('.description_short').slideUp(function () {
                $('.description_long').slideDown();
            });
        });
    }
}

function initContact() {
    if ($('#contact').length > 0) {

        var latlngs = $('#maps').data('markers').split(';');
        var latlngs = latlngs.slice(0, latlngs.length - 1);
        var latlng = new google.maps.LatLng();
        var bounds = new google.maps.LatLngBounds();

        if(latlngs.length > 1){
            map = new google.maps.Map(document.getElementById("maps"), {
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false
            });

            for (var i = 0; i < latlngs.length; i++) {

                var latlng = latlngs[i].split(',');

                if (latlng[0] !== '') {

                    latlng = new google.maps.LatLng(latlng[0], latlng[1]);

                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map,
                        icon: '/img/icon-map.png'
                    });

                    bounds.extend(marker.position);
                }

            }

            map.fitBounds(bounds);
        } else {
            latlng = latlngs[0].split(',');
            latlng = new google.maps.LatLng(latlng[0], latlng[1]);

            map = new google.maps.Map(document.getElementById("maps"), {
                zoom: 12,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                center: latlng
            });

            var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                icon: '/img/icon-map.png'
            });
        }

    }
}

function initAanbod() {
    initViewToggle();
}

function initViewToggle() {
    if ($('.view-toggle').length > 0) {
        $('.view-map').hide();

        $('.view-toggle').click(function () {

            $('.view-toggle').removeClass('active');
            $(this).addClass('active');

            $('.view').hide();
            $('.' + $(this).data('toggle')).show();

            localStorage['view-toggle'] = $(this).data('toggle');

            if ($(this).data('toggle') == 'view-map') {

                $('.sort_wrapper').hide();

                var view = $(this).data('method');

                $.ajax({
                    dataType: "json",
                    url: window.location.origin + window.location.pathname + '.json' + window.location.search,
                    method: 'GET',
                    async: true
                }).done(function (data) {
                    initMap(data);
                });
            } else {
                $('.sort_wrapper').show();
            }
        });
    }

    if (localStorage['view-toggle'] == 'view-map') {
        $('.view-toggle[data-toggle=view-map]').click();
    }
}

function initMap(data) {
    var bounds = new google.maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();

    map = new google.maps.Map(document.getElementById("maps"), {
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    markers = [];
    $(data).each(function (i, object) {

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(object.latitude, object.longitude),
            map: map,
            icon: '/img/icon-map.png'
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                infoBubble = new InfoBubble({
                    map: map,
                    content: '<a href="' + object.url + '" class="object">\n\
                                <figure>\n\
                                    <img src="' + object.hoofdfoto + '" alt="' + object.adres + ' ' + object.plaats + '">\n\
                                    <figcaption>' + object.prijs + '</figcaption>' + object.label + '\n\
                                </figure>\n\
                                <div class="text">\n\
                                    <h2>' + object.adres + '</h2>\n\
                                    <h3>' + object.plaats + '</h3>\n\
                                </div>\n\
                            </a>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 0,
                    arrowSize: 0,
                    borderWidth: 0,
                    minWidth: 320,
                    maxWidth: 320,
                    closeSrc: '/img/infobubble-close.png'
                });

                infoBubble.open(map, marker);
            }
        })(marker, i));

        markers.push(marker);
    });

    map.fitBounds(bounds);

    var mc = new MarkerClusterer(map, markers, {
        imagePath: '/img/m'
    });
}

function initServiceIcons() {
    $('.service-item img').hover(function () {
        var source = $(this).attr('src');
        $(this).attr('src', source.substr(0, source.indexOf('.png')) + '-mouseover.png');
    }, function () {
        var source = $(this).attr('src').replace('-mouseover.png', '.png');
        $(this).attr('src', source);
    });
}

function initObjectPano() {
    $('[id^=pano-]').each(function (index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initEasyEquals() {
    if ($('#details').length) {
        var height = 0,
            dt_height = 0,
            dd_height = 0;

        $('dl').each(function() {
            $(this).find('dt').each(function() {
                if ($(this).outerHeight() > $(this).next('dd').outerHeight()) {
                    height = $(this).outerHeight();
                } else {
                    height = $(this).next('dd').outerHeight();
                }
                $(this).css({
                    height: height,
                    verticalAlign: 'top'
                });
                $(this).next('dd').css({
                    height: height,
                    verticalAlign: 'top'
                });
            });
        });
    }
}